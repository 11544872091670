import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import Text from '../shared/Text';
import ScreenWrapper from '../shared/ScreenWrapper';
import StripeForm from '../shared/StripeForm';
import Button from '../shared/Button';
import Accordion from '../shared/Accordion';
import { LargeFoundersIcon, FoundersBadge, ProForever, ShoutOut, Trees } from '../../utility/icons';
import styles from '../../utility/styles';

const stripePromise = loadStripe("pk_live_51CaRknIQ3lwZ7caoaObXrxln4CAVhhS04Vdu451NXjTlyMFKFreMO4ODFn0CwhBY5ESuXDiua61T6RlouAgC6Adx00nd0UsBvA");
//const stripePromise = loadStripe(process.env.STRIPE_PUB_KEY);

const FoundersPill = styled.div`
    display: flex;
    flex: 0;
    padding: 4px 12px;
    background-color: ${styles.colors.brightYellow};
    justify-content: center;
    align-self: flex-start;
    align-items: center;
    margin-bottom: 12px;
    margin-top: 64px;
`;

const StyledContainer = styled(Container)`
    @media only screen and (max-width: 992px) {
        margin-top: 75px;
    }
`

const CenteredDiv = styled.div`
    display: flex;
    justify-content: center;
    flex: 1;
`;

const IconCol = styled.div`
    display: flex;
    height: 100px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    margin-bottom: 24px;
`;

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px;
    border-radius: 2px;
    border: 2px solid ${styles.colors.darkGray};
    margin: 0 24px 48px 24px;
`;

const StyledRow = styled(Row)`
    max-width: 100%;
    margin: auto;
`;

export default function Founders({ faq, founders_price }) {
    return (
        <ScreenWrapper>
            <StyledContainer style={{ maxWidth: '760px' }}>
                <StyledRow>
                    <Col sm={7} className='d-flex flex-column'>
                        <FoundersPill>
                            <Text.Caption variant='bold' style={{ color: styles.colors.black }}>Founders Series</Text.Caption>
                        </FoundersPill>
                        <Text.Huge variant='bold' type='mega' color={styles.colors.brightYellow} mobileL='h2'>The Race to Carbon Neutral</Text.Huge>
                    </Col>
                    <Col sm={5} className='d-flex justify-content-end'>
                        <LargeFoundersIcon style={{transform: 'translateX(24px)'}} />
                    </Col>
                </StyledRow>
                <StyledRow>
                    <Col>
                        <Text.Heading type='h4' style={{ marginBottom: '16px' }} color={styles.colors.whiteGray}>Sustainability and tech is where rubber meets the road for TezLab.</Text.Heading>
                        <Text.Para color={styles.colors.whiteGray}>We set out to accomplish one monumental task - build the very best app for the Tesla community to help in the mission of sustainable transport. Now, more than ever, this is mission-critical, and we need your help!</Text.Para>
                        <br />
                        <Text.Para color={styles.colors.whiteGray}>Like many great companies, what started as a passion project has evolved into a massively growing community and essential business. We are so thrilled by the kind words, accolades, and ongoing support from our community. It truly means the world.</Text.Para>
                        <br />
                        <Text.Para color={styles.colors.whiteGray}>Internally, we've been having many discussions about how best to keep up with our growth in a natural and sustainable way with user experience and privacy as top priorities. Taking a nod from Tesla, we are happy to announce our new Founder Series Program to help fund our substantial growth and mounting costs.</Text.Para>
                        <br />
                        <Text.Para color={styles.colors.whiteGray}>For a one-time contribution, we invite you to join our Founder Series Program. This program is an opportunity to take part in the future development of TezLab and further participate in the larger sustainable transport movement. Founders Series members receive complimentary lifetime unlimited access to TezLab, have the option of displaying either a Founders Series or TezLab Pro badge on their profile, display their name on our website, and help make sustainable transport a reality.</Text.Para>
                        <br />
                        <Text.Para color={styles.colors.whiteGray}>As we continue to do our best in the sustainable transport movement, we've launched a massive tree planting initiative to give back to this glorious planet. While electric vehicles are much cleaner than internal combustion engine (ICE) vehicles, carbon is still almost always released in varying levels through the electricity generation process. We've started exposing more of this information in-app, and have now enabled the TezLab community to offset charging emissions by planting trees with a few taps. Tree planting is fun, cost-effective, and will help make all of our EV transport even more sustainable. To kick off our tree planting initiative, each Founders Series member will also have a tree planted in their name.</Text.Para>
                        <br />
                        <Text.Para color={styles.colors.whiteGray}>Let’s come together, plant a million trees, and work towards a better, fully sustainable planet.</Text.Para>
                        <br />
                        <Text.Para color={styles.colors.whiteGray}>We truly appreciate your support.</Text.Para>
                        <br />
                        <Text.Body style={{ marginBottom: '32px' }} color={styles.colors.whiteGray}>~ The TezLab Team</Text.Body>
                        <CenteredDiv style={{ marginBottom: '90px' }}>
                            <Button variant='primary' as='a' href='/founders/list'>
                                <Text.Body variant='bold'>View Founders Series List</Text.Body>
                            </Button>
                        </CenteredDiv>
                    </Col>
                </StyledRow>
                <StyledRow style={{ marginBottom: '66px' }}>
                    <Col md={3} sm={6} xs={12}>
                        <IconCol>
                            <FoundersBadge />
                            <Text.Caption variant='bold' color={styles.colors.lightGray}>Founders Series Badge</Text.Caption>
                        </IconCol>
                    </Col>
                    <Col md={3} sm={6} xs={12}>
                        <IconCol>
                            <ShoutOut />
                            <Text.Caption variant='bold' color={styles.colors.lightGray}>Website Shout Out</Text.Caption>
                        </IconCol>
                    </Col>
                    <Col md={3} sm={6} xs={12}>
                        <IconCol>
                            <Trees />
                            <Text.Caption variant='bold' color={styles.colors.lightGray}>Tree In Your Name</Text.Caption>
                        </IconCol>
                    </Col>
                    <Col md={3} sm={6} xs={12}>
                        <IconCol>
                            <ProForever />
                            <Text.Caption variant='bold' color={styles.colors.lightGray}>Tezlab Pro. Forever.</Text.Caption>
                        </IconCol>
                    </Col>
                </StyledRow>
                <FormWrapper>
                    <Text.Heading variant='bold' type='h2' color={styles.colors.whiteGray} style={{ textAlign: 'center', marginBottom: '24px' }}  mobileL='h3'>Together, we can reach our goal of planting a million trees.</Text.Heading>
                    <Text.Heading type='h5' color={styles.colors.whiteGray} style={{ textAlign: 'center', maxWidth: '90%', marginBottom: '40px' }} mobileL='body'>Join the TezLab Founders Series for a base contribution of ${founders_price} (USD) and help us push sustainability forward.</Text.Heading>
                    <Elements stripe={stripePromise}>
                        <StripeForm founders_price={founders_price} />
                    </Elements>
                </FormWrapper>
                <Text.Heading variant='bold' type='h2' style={{ marginBottom: '42px', textAlign: 'center' }} mobileL='h3'>FAQ</Text.Heading>
                <Accordion rows={faq} style={{ margin: '0 24px 90px 24px' }} />
            </StyledContainer>
        </ScreenWrapper>
    )
}