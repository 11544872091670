import React from 'react';
import { Form, FormGroup, Row, Col, Container, InputGroup, Alert, FormControl, FloatingLabel, Spinner } from 'react-bootstrap';
import validator from 'validator';
import styled from 'styled-components';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import Button from './Button';
import { AltDone } from '../../utility/icons';
import styles from '../../utility/styles';

const STANDARD_ERROR = 'We apologize! Something went wrong with the payment request. Please Try Again.'
const ALREADY_FOUNDER_ERROR = 'It looks like you\'re already a Founders Series member. Thanks very much for your support! We have not processed this additional registration.'

const EMAIL_NOUSER_ERROR = "Hmm, we can't find an account with that email address."
const EMAIL_DUPFOUNDER_ERROR = "You're already a Founders Series member. Thanks for your support!"
const EMAIL_PROCESSING_ERROR = "Payment for your Founders Series registration is processing. Please contact support@tezlabapp.com if it does not complete within an hour or so."
/**
 * 
 * @param {string} string 
 * @returns 
 */
const returnAmount = string => {
    return Number(string)
}

const cardOptions = {
    style: {
        base: {
            color: styles.colors.white,
            fontFamily: styles.fonts.nunito,
            fontWeight: 800,
            fontSize: "14px",
            "::placeholder": {
                fontFamily: styles.fonts.nunito,
                color: styles.colors.lightGray
            },
            iconColor: styles.colors.white
        },
        invalid: {
            color: "#E65050",
            iconColor: "#E65050"
        }
    }
}

const StyledForm = styled(Form)`
    width: 100%;
`;

const StyledInput = styled(FormControl)`
    background: ${styles.colors.darkGray};
    border-radius: 1px;
    border: none;
    color: ${styles.colors.white};
    font-size: 16px;
    line-height: 24px;
    font-weight: 800;
    padding: 20px 12px;
    &::placeholder {
        color: ${styles.colors.lightGray};
    }
    &:focus {
        background: ${styles.colors.darkGray};
        color: ${styles.colors.white};
    }
    &:-webkit-autofill,&:-webkit-autofill:hover,&:-webkit-autofill:focus {
        border: none;
        -webkit-text-fill-color: ${styles.colors.white};
        -webkit-box-shadow: 0 0 0 1000px ${styles.colors.darkGray} inset;
    }
    @media only screen and (max-width: 390px) {
        font-size: 14px;
        line-height: 20px;
    }
`;

const CardWrapper = styled.div`
    background-color: ${styles.colors.darkGray};
    padding: 12px 14px;
    border-radius: 1px;
    margin-bottom: 12px;
`;

const CenteredDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledDolla = styled.span`
    font-size: 16px;
    line-height: 24px;
    font-weight: 800;
    background-color: ${styles.colors.darkGray};
    padding-right: 0;
    border: none;
    color: ${styles.colors.white};
`;

const StyledSpan = styled.span`
    background-color: ${styles.colors.darkGray};
    border: none;
`;

export default function StripeForm({ founders_price }) {
    const stripe = useStripe();
    const elements = useElements();

    const [email, setEmail] = React.useState('');
    const [amount, setAmount] = React.useState('');
    const [error, setError] = React.useState('');
    const [success, setSuccess] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [emailMessage, setEmailMessage] = React.useState(null); 
    const [emailLoading, setEmailLoading] = React.useState(false);
    const [emailValidated, setEmailValidated] = React.useState(false);

    const handleEmailChange = React.useCallback( async ({ target: { value } }) => {
        setEmailMessage(null);
        setEmailValidated(false);
        setEmail(value);
        
        // Validate that the user is eligible
        if (validator.isEmail(value)){
            setEmailLoading(true);
            try {
                // Post to the email validation endpoint
                const response = await fetch('/v2/founder/email', {
                    method: 'POST',
                    body: JSON.stringify({ email: value.trim()}),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (!response.ok) throw STANDARD_ERROR
                const json = await response.json();
                const {
                    user_validated,
                    is_founder,
                    is_processing
                 } = json;
    
                 if (user_validated === false) throw EMAIL_NOUSER_ERROR
                 if (is_founder === true) throw EMAIL_DUPFOUNDER_ERROR
                 if (is_processing === true) throw EMAIL_PROCESSING_ERROR
    
                 if (!is_founder && user_validated && !is_processing){
                    setEmailLoading(false);
                    setEmailValidated(true);
                    setEmailMessage(null);
                 }
                
            } catch (error) {
                const message = typeof error === 'string' ? error : STANDARD_ERROR
                setEmailLoading(false)
                setEmailMessage(message)
            }

        }
    }, [setEmail, setEmailLoading, setEmailValidated, setEmailMessage])

    const handlePriceChange = React.useCallback(({ target: { value } }) => {
        if (value <= 1000) setAmount(value);     
    }, [setAmount])


    const handleSubmit = React.useCallback(async () => {

        if (!stripe || !elements) return;
        
        const card = elements.getElement(CardElement);
        setLoading(true);
        setError('');
        try {
            // post the intent
            const response = await fetch('/v2/founder/intent', {
                method: 'POST',
                body: JSON.stringify({ email: email.trim(), amount: returnAmount(amount) }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) throw STANDARD_ERROR
            const json = await response.json();
            const {
                payment_amount,
                client_secret,
                is_founder,
                is_processing
             } = json;

             if (!client_secret) throw STANDARD_ERROR
             if (is_founder) throw ALREADY_FOUNDER_ERROR

             // pay with the elements baby!
             const result = await stripe.confirmCardPayment(client_secret, {
                 payment_method: {
                     card
                 }
             })

             // handle payment errors
             if (result.error) throw result.error.message

             // update UI
             setLoading(false)
             setSuccess(true)
        } catch (error) {
            const message = typeof error === 'string' ? error : STANDARD_ERROR
            setLoading(false)
            setError(message)
        }
    }, [stripe, elements, email, amount])

    return (
        <div style={{ width: '100%' }}>
            <CenteredDiv>
                <Alert variant='success' show={success} transition={false}>
                    Thank you for your support! You will receive a confirmation by email shortly.
                </Alert>
            </CenteredDiv>

            {!success && (
                <>
                    <StyledForm>
                        <Container fluid>
                            <Row>
                                <Col sm={12} md={8} style={{ marginBottom: '12px' }}>
                                    <InputGroup>
                                        <StyledInput
                                            placeholder='TezLab Account Email'
                                            value={email}
                                            onChange={handleEmailChange}
                                            type='email'
                                        />
                                        {emailValidated && !emailLoading && (
                                            <StyledSpan className="input-group-addon">
                                                <AltDone />
                                            </StyledSpan>
                                        )}
                                        {emailLoading && !emailValidated && (
                                            <StyledSpan className="input-group-addon">
                                                <Spinner 
                                                    as='span' 
                                                    animation='border' 
                                                    role='status'
                                                    aria-hidden='true'
                                                />
                                            </StyledSpan>
                                        )}
                                    </InputGroup>
                                    { (emailMessage && typeof emailMessage === 'string') && (
                                        <Form.Text>{emailMessage}</Form.Text>
                                    )}
                                </Col>
                                <Col sm={12} md={4} style={{ marginBottom: '12px' }}>
                                    <FormGroup>
                                        <InputGroup>
                                            {Boolean(amount) && <StyledDolla className="input-group-addon" style={{ background: styles.colors.darkGray }}>$</StyledDolla>}
                                            <StyledInput
                                                style={amount ? { paddingLeft: 0 } : {}}
                                                placeholder='Amount'
                                                value={amount}
                                                onChange={handlePriceChange}
                                                type='number'
                                            />
                                            {Number(amount) >= founders_price && (
                                                <StyledSpan className="input-group-addon">
                                                    <AltDone />
                                                </StyledSpan>
                                            )}
                                        </InputGroup>
                                        <Form.Text>Min. Contribution US${founders_price}</Form.Text>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <CardWrapper>
                                        <CardElement options={cardOptions}/>
                                    </CardWrapper>
                                </Col>
                            </Row>
                        </Container>
                    </StyledForm>
                    <CenteredDiv>
                        <Alert variant='danger' show={Boolean(error)} transition={false}>{error}</Alert>
                        <Button
                            {...{ loading, success }}
                            disabled={loading || !emailValidated || Number(amount) < founders_price || Number(amount >= 1000) || success}
                            onClick={handleSubmit}
                        >
                            {loading ? 'Loading' : success ? 'Registration Received - Thank You!' : 'Complete'}
                        </Button>
                    </CenteredDiv>
                </>

            )}
            
        </div>
    )

}

